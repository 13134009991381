const Seperator = () => {
  return (
    <div
      style={{
        paddingBottom: "3px",
        paddingLeft: "10px",
        paddingRight: "10px",
        color: "#c9b7db",
      }}
    >
      |
    </div>
  );
};

export default Seperator;
